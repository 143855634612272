export default function addUriFragment(uri, fragment) {
  if (!fragment) {
    return uri;
  }

  fragment = fragment.replace(/^#/, "");
  return !uri
    ? `#${fragment}`
    : uri.indexOf("#") > -1
    ? `${uri}&${fragment}`
    : `${uri}#${fragment}`;
}
