import React, { Fragment } from 'react';
import { ContactUsLink, PlaidIdentityVerificationError } from './PlaidIdentityVerificationError';
import { updateProfileLink } from '../../../src/utils/profileLinks';
import Button from '@material-ui/core/Button';

const PhoneNotAvailableError = () => {
  return (
    <PlaidIdentityVerificationError>
      <Fragment>
        The phone number you provided is associated with another account. Please visit the My account section of your
        Invitation Homes dashboard to add a different phone number.
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Button
            style={{ textTransform: 'none' }}
            color="primary"
            size="large"
            variant="contained"
            href={updateProfileLink()}
          >
            Edit my account
          </Button>
        </div>
        If your issue persists, please <ContactUsLink />.
      </Fragment>
    </PlaidIdentityVerificationError>
  );
};

export default PhoneNotAvailableError;
