/* @flow */
import axios from "axios";
import { extend } from "lodash";

class BaseService {
  options: Object;

  connector: Object;

  constructor(options: Object = {}) {
    this.options = {
      base_url: "/",
      ...options
    };
    this.connector =
      this.options.connector ||
      axios.create({
        ...(options.connector_options || {}),
        baseUrl: this.options.base_url
      });
  }

  options(config: Object = {}) {
    return {
      ...(this.options.default_options || {}),
      ...config
    };
  }

  headers(config: Object = {}) {
    return {
      ...config,
      ...(this.options.default_headers || {})
    };
  }

  endpoint(path: string = ""): string {
    return `${this.options.base_url}${path}`;
  }

  request(method: string = "GET", endpoint: string = "", data: Object = {}, config: Object = {}) {
    // Configure the options
    const options = {
      ...config,
      method: method.toUpperCase(),
      data,
      url: this.endpoint(endpoint),
      headers: config.headers ? config.headers : this.headers({})
    };

    // Return the request data (or false)
    return this.connector.request(options)
      .then(res => (this.options.raw ? res : res.data) || null)
      .catch(err => {
        if (axios.isCancel(err)) {
          console.warn('Request canceled:', err.message);
        } else {
          return Promise.reject(err);
        }
      });
  }

  get(endpoint: string = "", options: Object = {}, data: Object = {}) {
    return this.request("get", endpoint, data, options);
  }

  post(endpoint: string = "", data: Object = {}, options: Object = {}) {
    return this.request("post", endpoint, data, options);
  }

  put(endpoint: string = "", data: Object = {}, options: Object = {}) {
    return this.request("put", endpoint, data, options);
  }

  delete(endpoint: string = "", options: Object = {}) {
    return this.request("delete", endpoint, {}, options);
  }

  search(endpoint: string = "", data: Object = {}, options: Object = {}) {
    return this.request("search", endpoint, data, options);
  }

  generateCancelToken() {
    return axios.CancelToken.source();
  }
}
module.exports = BaseService;
