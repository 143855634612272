import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import axios from 'axios';
import * as googleTagManager from './google-tag-manager';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import InitializationError from './InitializationError';
import PhoneNotAvailableError from './PhoneNotAvailableError';

const PlaidIdentityVerification = ({ templateId, unit, onSuccessfulVerification, onFailedVerification }) => {
  const [token, setToken] = useState(null);
  const [errorBeginningSession, setErrorBeginningSession] = useState(false);
  const [phoneNumberAvailable, setPhoneNumberAvailable] = useState(true);
  const [endingIdentityVerificationSession, setEndingIdentityVerificationSession] = useState(false);
  const currentStep = useRef(null);

  useEffect(() => {
    const createLinkToken = async () => {
      axios
        .post('/api/v1/plaid/begin-identity-verification-session', {
          templateId,
          propertyCode: unit.unit_code,
        })
        .then((response) => {
          setToken(response.data.link_token);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            onFailedVerification(true);
          } else {
            setErrorBeginningSession(true);
          }
        });
    };
    createLinkToken();
  }, []);

  const plaidEventHandlers = {
    IDENTITY_VERIFICATION_CLOSE_UI: async (metadata) => {
      setEndingIdentityVerificationSession(true);
      const endSessionResponse = await axios.post(
        `/api/v1/plaid/end-identity-verification-session/${metadata.link_session_id}`,
      );
      setEndingIdentityVerificationSession(false);
      if (!endSessionResponse.data.phone_number_available_and_updated) {
        setPhoneNumberAvailable(false);
      } else if (endSessionResponse.data.identity_verification_status === 'success') {
        onSuccessfulVerification();
      } else {
        onFailedVerification(endSessionResponse.data.reached_maximum_failed_attempts);
      }
    },
    IDENTITY_VERIFICATION_START_STEP: async (metadata) => {
      // capture current step in order to track abandons (see onExit below)
      currentStep.current = metadata.view_name;
    },
    IDENTITY_VERIFICATION_PASS_STEP: async (metadata) => {
      googleTagManager.passedStep(unit, metadata);
    },
    IDENTITY_VERIFICATION_FAIL_STEP: async (metadata) => {
      googleTagManager.failedStep(unit, metadata);
    },
    IDENTITY_VERIFICATION_PASS_SESSION: async (metadata) => {
      googleTagManager.passedSession(unit, metadata);
    },
    IDENTITY_VERIFICATION_FAIL_SESSION: async (metadata) => {
      googleTagManager.failedSession(unit, metadata);
    },
  };

  const { open, ready } = usePlaidLink({
    token,
    onEvent: useCallback(async (eventName, metadata) => {
      const eventHandler = plaidEventHandlers[eventName];
      if (eventHandler) {
        await eventHandler(metadata);
      }
    }),
    onSuccess: () => {},
    onExit: (error, metadata) => {
      googleTagManager.abandonedFlow(currentStep.current, unit, metadata);
    },
  });

  React.useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  if (!phoneNumberAvailable) {
    return <PhoneNotAvailableError />;
  }

  if (errorBeginningSession) {
    return <InitializationError />;
  }

  if (endingIdentityVerificationSession) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
        <CircularProgress variant="indeterminate" thickness={2} style={{ color: '#C0C0C0', textAlign: 'center' }} />
      </Box>
    );
  }

  return <></>;
};

export default PlaidIdentityVerification;
