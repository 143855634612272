// @flow
const BaseService = require("./base");

class OAuthService extends BaseService {
  grant = {};

  constructor(options: Object = {}) {
    super(options);
    this.options.headers_middleware = this.headers.bind(this);
  }

  accessToken() {
    return this.options.grant ? this.options.grant.access_token : "";
  }

  headers(headers: Object = {}) {
    return {
      ...headers,
      Authorization: `Bearer ${this.accessToken()}`
    };
  }
}

module.exports = OAuthService;
