import { conformToMask } from "text-mask-core";
import assert from "./assert";

const mask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export default phone => {
  assert(phone.length >= 10, "Partial phone number passed in");
  return conformToMask(phone, mask, { guide: false }).conformedValue;
};
