import assert from "./assert";

const expectedUnitValues = ["streetAddress1", "city", "state", "zip"];

export default unit => {
  expectedUnitValues.forEach(key => assert(unit[key], `${key} is not set`));
  const streetAddress = unit.streetAddress2
    ? `${unit.streetAddress1} ${unit.streetAddress2}`
    : unit.streetAddress1;
  return `${streetAddress}, ${unit.city}, ${unit.state} ${unit.zip}`;
};
